<template>
  <div class="details">
    <Header ref="header" />
    <div class="main">
      <div class="title">{{artObj.title}}</div>
      <div class="time">发布时间：{{artObj.createTime}}</div>
      <img class="line" src="../assets/home/line3@2x.png" alt="">
      <div class="content" v-html="artObj.content">
    </div>

    </div>
  </div>
</template>

<script>
  import Header from "./header";
  import { getArticleById } from '../api'
export default {
  name: 'details',
  data(){
    return{
      artObj:{}
    }
  },
  components:{
    Header
  },
  methods:{
    getArticleById() {
      getArticleById({id: this.id}).then(res => {
        this.artObj = res.data
        console.log(res, '我是返回的文章详情')
      })
    }
  },
  mounted() {
    console.log(this.$refs.header,1111111)
    this.id = this.$route.query.id
    this.getArticleById()
  },
}
</script>

<style lang="scss" scoped>
  .main{
    padding: 30px 24px 0 24px;
    .title{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
      line-height: 22px;
      text-align: center;
      margin-bottom: 12px;
    }
    .time{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9B9B9B;
      line-height: 17px;
      text-align: center;
      margin-bottom: 13px;
    }
    .line{
      height: 2px;
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    ::v-deep {
      .content{
        img {
          width: 100%;
        }
      }
    }
  }
</style>
